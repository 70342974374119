import React from 'react'
import { SelectMultiple } from './FormElement'
import { SelectMultipleType } from '../types/ATNTypes'
import { useIntl, MessageDescriptor } from 'react-intl'
import { getMultilingualATNLabel } from '../utils/languageHelpers'
import { PrimitiveType } from '../types/PrimitiveType'

interface FormConsentProps {
  templateId: string
  activeLang: string
}

const consentElement = (
  templateId: string,
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType> | undefined,
  ) => string,
) => {
  return {
    id: `consents.${templateId}`,
    type: 'selectMultiple',
    required: true,
    label: getMultilingualATNLabel(formatMessage({ id: 'consent.titleLabel' })),
    errorMessage: getMultilingualATNLabel(
      formatMessage({ id: 'consent.errorMessage' }),
    ),
    style: {
      position: 'left',
      optionsArrangement: 'vertical',
    },
    options: [
      {
        id: 'yes',
        type: 'checkbox',
        label: getMultilingualATNLabel(
          formatMessage({ id: 'consent.optionLabel' }),
        ),
        addToAnamnesisReport: true,
        anamnesisReportLabel: 'consent',
        children: [],
      },
    ],
  } as SelectMultipleType
}

export const FormConsent = ({ templateId, activeLang }: FormConsentProps) => {
  const { formatMessage } = useIntl()

  return (
    <SelectMultiple
      activeLang={activeLang}
      element={consentElement(templateId, formatMessage)}
      templateId={templateId}
    />
  )
}
