import { languages, uiLanguages } from './languages'

export const languageKeys = languages.map((l) => l.key)

export const getLanguage = (key: string) =>
  languages.filter((l) => l.key === key)[0]

export const isUILanguage = (key: string) => uiLanguages.includes(key)

export const orderLanguages = (languages: Array<string>) => {
  // clean up ch: remove from string (https://jira.damp.local/browse/AE-943)
  const order = [
    'de',
    'en',
    'fr',
    'es',
    'tr',
    'ru',
    'ch',
    'zh',
    'ja',
    'ar',
    'uk',
    'pl',
  ]
  const defaultLanguages = languages
    .filter((l) => order.includes(l))
    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
  const customLanguages = languages.filter((l) => !order.includes(l)).sort()

  return defaultLanguages.concat(customLanguages)
}
