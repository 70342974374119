import { DynamicSelectOneType } from '../../../../types/ATNTypes'
import {
  DynamicSelectOneOptionsType,
  DynamicSelectOneOptionType,
} from './types'

function getOptionLabel(option: DynamicSelectOneOptionType) {
  const labeText = option.label ? `(${option.label})` : ''
  return `${option.number || option.address} ${labeText}`
}

export function generateCustomDynamicSelectOneWithOptions(
  element: DynamicSelectOneType,
  options: DynamicSelectOneOptionsType,
) {
  const modifiedElement = element

  modifiedElement.options = options.map((option) => ({
    id: `id_${option.id}`,
    type: 'radioButton',
    ds4FieldValue: option.id,
    value: option.id,
    label: {
      de: getOptionLabel(option),
    },
    children: [],
  }))

  return modifiedElement
}
