import React, { useContext } from 'react'
import { LanguageContext } from '../contexts/LanguageContext'
import styled from 'styled-components'
import { Select } from '@material-ui/core'
import { colors } from '../utils/theme'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiOutlinedInput-root {
    height: 45px;
  }
  .MuiSelect-select:focus {
    background-color: ${colors.white};
  }
`

export const LanguagePicker = () => {
  const { locale, setLocale, supportedLanguages } = useContext(LanguageContext)

  if (supportedLanguages.length <= 1) return <div />

  return (
    <Container>
      <Select
        native
        id="language-select"
        variant="outlined"
        value={locale}
        autoComplete="off"
        autoSave="off"
        autoCorrect="off"
        onChange={(e) => e.target.value && setLocale(e.target.value as string)}>
        {supportedLanguages.map((language) => (
          <option
            key={language.key}
            value={language.key}
            disabled={!language.messages}>
            {language.label}
          </option>
        ))}
      </Select>
    </Container>
  )
}
