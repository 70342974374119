import React from 'react'
import styled from 'styled-components'
import { Card } from '../components/Card'
import { AthenaLogo } from '../components/AthenaLogo'
import { FormattedMessage } from 'react-intl'
import { Grid, Link, Typography } from '@material-ui/core'
import { StyledHr } from '../components/FormElement'
import { theme } from '../utils/theme'
import { athenaUrl } from '../config'

const Wrapper = styled.div`
  padding-top: 20px;

  ${theme.breakpoints.down('sm')} {
    padding-top: 10px;
  }
`

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 20px;

  img {
    max-width: 100%;
  }
`

interface FallbackProps {
  error?: boolean
}

export const Fallback = (props: FallbackProps) => {
  const { error } = props

  return (
    <Wrapper>
      <Card>
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <>
                <Typography variant="h1">
                  <FormattedMessage id="fallback.errorTitle" />
                </Typography>
                <p>
                  <FormattedMessage id="fallback.errorParagraph" />
                </p>
                <StyledHr />
              </>
            ) : (
              <Typography variant="h1">
                <FormattedMessage id="fallback.title" />
              </Typography>
            )}
            <p>
              <FormattedMessage id="fallback.paragraph1" />
            </p>
            <p>
              <FormattedMessage id="fallback.paragraph2" />
            </p>
            <p>
              <FormattedMessage id="fallback.paragraph3" />
            </p>
            <p>
              <FormattedMessage id="fallback.moreInfo" />:{' '}
              <Link target="_blank" href={athenaUrl}>
                {athenaUrl.split('//')[1]}
              </Link>
            </p>
          </Grid>
          <IconWrapper>
            <AthenaLogo />
          </IconWrapper>
        </Grid>
      </Card>
    </Wrapper>
  )
}
