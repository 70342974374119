import React, { useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { useRecallElement } from '../../../../contexts/RecallElementProvider'
import {
  getElementIdInATN,
  isElementShownByRecallType,
  isRecallToEmail,
  isRecallToEmailOrPhoneElement,
  isRecallToPhone,
} from '../../../../utils/recallHelpers'
import { DynamicSelectOneOptionsType, DynamicSelectOneProps } from './types'
import { SelectOne } from '../../../FormElement'
import { DocumentFlowContext } from '../../../../contexts/DocumentFlowContext'
import { generateCustomDynamicSelectOneWithOptions } from './helpers'
import { DynamicSelectOneType } from '../../../../types/ATNTypes'

const DynamicSelectOne: React.FC<DynamicSelectOneProps> = ({
  element,
  activeLang,
  templateId,
}) => {
  const { patientType, recallType } = useRecallElement()
  const { templates } = useContext(DocumentFlowContext)
  const templateIndex = parseInt(templateId)
  const templateValues = (useFormikContext().values as any).document_templates[
    templateIndex
  ]
  const atn = templates[templateIndex].atn

  let recallElementId: string = useMemo(() => {
    if (isRecallToPhone(element)) {
      recallElementId = getElementIdInATN('telefonnummern', atn)
    } else if (isRecallToEmail(element)) {
      recallElementId = getElementIdInATN('emailadressen', atn)
    }

    return recallElementId
  }, [atn, element])

  if (
    !isRecallToEmailOrPhoneElement(element) ||
    !isElementShownByRecallType(element, recallType, patientType)
  ) {
    return null
  }

  const options: DynamicSelectOneOptionsType =
    get(templateValues, recallElementId) || []

  if (options.length === 0) {
    return null
  }

  const modifiedElement = generateCustomDynamicSelectOneWithOptions(
    element as DynamicSelectOneType,
    options,
  )

  return (
    <SelectOne
      element={modifiedElement}
      activeLang={activeLang}
      templateId={templateId}
    />
  )
}

export default DynamicSelectOne
