import { FastField, FieldInputProps, FieldProps } from 'formik'
import React, { useMemo, useState } from 'react'
import countryList from 'react-select-country-list'
import Select from 'react-select'

import { InputContainer } from '../../../FormElement'
import { TextInputProps } from '../TextInput/types'
import { validateInput } from '../helpers'
import { getInputWidth } from '../TextInputGroup/helpers'
import { CountryDropdownOption } from './types'
import { colors } from '../../../../utils/theme'

const DEFAULTCOUNTRY = {
  label: 'Germany',
  value: 'DE',
}

const CountryInput = ({ activeLang, element }: TextInputProps) => {
  const options = useMemo(() => countryList().getData(), [])
  const [selectedCountry, setSelectedCountry] = useState<CountryDropdownOption>(
    DEFAULTCOUNTRY,
  )

  const inputWidth = useMemo(
    () => getInputWidth(element.style?.size as 's' | 'm' | 'l'),
    [element.style],
  )

  const handleCountrySelected = (
    option: CountryDropdownOption,
    field: FieldInputProps<any>,
  ) => {
    if (!option) return
    const event = ({
      target: {
        name: field.name,
        value: option.value,
      },
    } as unknown) as React.ChangeEvent<HTMLInputElement>
    field.onChange(event)
    setSelectedCountry(option)
  }

  return (
    <InputContainer id={element.id}>
      <FastField
        key={`${element.id}`}
        name={element.id}
        validate={(value: string) => validateInput(value, element, activeLang)}>
        {({ field }: FieldProps) => {
          if (!Boolean(field.value))
            handleCountrySelected(DEFAULTCOUNTRY, field)
          return (
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? 'grey' : 'red',
                  width: inputWidth,
                  height: '46px',
                  border: `1px solid ${colors.blue}`,
                  borderRadius: '5px',
                }),
              }}
              options={options}
              value={selectedCountry}
              onChange={(selectedOption: CountryDropdownOption) =>
                handleCountrySelected(selectedOption, field)
              }
              name={field.name}
            />
          )
        }}
      </FastField>
    </InputContainer>
  )
}

export default CountryInput
