import { RadioButtonType } from '../types/ATNTypes'
import { RecallElements } from '../types/Practice'

type RecallOptionsType = keyof RecallElements

const optionsMap: { [key: string]: string } = {
  id_daba28ec_06df_4d87_b5bf_39c0d2a7d80e: 'recall_letter',
  id_ad5a420f_08ce_4a75_8168_a9d67998502c: 'recall_sms',
  id_0ab7ed43_4321_479a_9e13_3081d6572b11: 'recall_email',
  id_83ca4168_ff28_4c78_831d_4d0a6e1854b9: 'recall_phone',
}

export const recallElementOptions = (
  options: Array<RadioButtonType>,
  recall_elements: RecallElements,
) => {
  const optionsToDisplay: RadioButtonType[] = []

  Object.keys(optionsMap).forEach((optionId) => {
    const optionKey = optionsMap[optionId] as RecallOptionsType
    let selectedOption = options.find((o) => o.id === optionId)
    if (recall_elements[optionKey] && selectedOption) {
      optionsToDisplay.push(selectedOption as RadioButtonType)
    }
  })

  return optionsToDisplay
}
