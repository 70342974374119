import styled from 'styled-components'
import React from 'react'
import { Link } from '@material-ui/core'
import { athenaUrl } from '../config'

interface Props {
  alt?: string
  src: string
}

const Image = styled.img<Props>`
  height: 60px;
`

export const DampsoftLogo = () => {
  return (
    <Link href={athenaUrl} target="_blank">
      <Image alt="dampsoft-logo" src="logo.webp" />
    </Link>
  )
}
