import React, { useState } from 'react'
import { LanguageContext } from './LanguageContext'
import { IntlProvider } from 'react-intl'
import { getLanguage, orderLanguages } from '../translations/languageConfig'

export const LanguageProvider = (props: any) => {
  const setLocale = (locale: string) => {
    updateLanguage((prevState) => {
      const newState = { ...prevState }
      return Object.assign(newState, {
        locale,
      })
    })
  }
  const setSupportedLanguages = (supportedLanguages: string[]) => {
    updateLanguage((prevState) => {
      const newState = { ...prevState }
      const browserLocale = navigator.language.split(/[-_]/)[0]
      const orderedList = orderLanguages(supportedLanguages)
      return Object.assign(newState, {
        supportedLanguages: orderedList
          .map((l) => getLanguage(l))
          .filter(Boolean), // filter out unsupported languages
        locale: orderedList.includes(browserLocale)
          ? browserLocale
          : orderedList[0], //for the flow with default templates, first language is 'de'
      })
    })
  }

  const languageState = {
    locale: 'de',
    supportedLanguages: [],
    setLocale,
    setSupportedLanguages,
  }

  const [language, updateLanguage] = useState(languageState)

  const msg = getLanguage(language.locale).messages

  return (
    <LanguageContext.Provider value={language}>
      <IntlProvider
        locale={language.locale}
        messages={msg}
        defaultLocale={'de'}>
        {props.children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}
