import React from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import { GlobalStyle, theme } from '../utils/theme'
import { LanguageProvider } from '../contexts/LanguageProvider'
import { Fallback } from './Fallback'

export const Error = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <LanguageProvider>
        <GlobalStyle />
        <Fallback error />
      </LanguageProvider>
    </MuiThemeProvider>
  )
}
