import styled from 'styled-components'
import React, { useContext } from 'react'
import { PracticeContext } from '../contexts/PracticeContext'
import { theme, colors } from '../utils/theme'
import { DocumentFlowContext } from '../contexts/DocumentFlowContext'
import { FormattedMessage } from 'react-intl'

const ProgressIndicator = styled.div`
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.grey};

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

export const StepProgressIndicator = () => {
  const { name } = useContext(PracticeContext)
  const { stage, stepIndex, templates } = useContext(DocumentFlowContext)
  const progress = (((stepIndex + 1) / (templates.length + 2)) * 100).toFixed(0)

  return (
    <ProgressIndicator>
      {stage === 'documentFlow' ? (
        <span>
          <FormattedMessage id="documentFlow.progress" />: {progress}%
        </span>
      ) : (
        name
      )}
    </ProgressIndicator>
  )
}
