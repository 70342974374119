import React, { useState } from 'react'
import { PatientInterface } from '../types/Patient'
import { InvitationDetailsContext } from './InvitationDetailsContext'

export const InvitationDetailsProvider = (props: any) => {
  const setPatient = (patient: PatientInterface) => {
    updateInvitationDetailsState((prevState) => {
      return { ...prevState, patient }
    })
  }

  const invitationDetailsState = {
    patient: null as PatientInterface | null,
    setPatient,
  }

  const [invitationDetails, updateInvitationDetailsState] = useState(
    invitationDetailsState,
  )

  return (
    <InvitationDetailsContext.Provider value={invitationDetails}>
      {props.children}
    </InvitationDetailsContext.Provider>
  )
}
