import React, { PureComponent, useContext } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { colors, theme } from '../utils/theme'
import { getPatient } from '../contexts/PatientContext'
import { PracticeLogo } from './PracticeLogo'
import {
  ATNValueType,
  HeadingType,
  GroupType,
  ListType,
  ParagraphType,
  TextType,
  SeparatorType,
  SpacerType,
  ImageType,
  TextareaType,
  SelectOneType,
  SelectMultipleType,
  VideoType,
  YesNoType,
  ArrangementType,
  RadioButtonType,
  CheckboxType,
  RecallType,
  DynamicSelectOneType,
} from '../types/ATNTypes'
import {
  FieldProps,
  FastField,
  useFormikContext,
  Field,
  ErrorMessage,
  useField,
  FormikContextType,
} from 'formik'
import { PracticeContext } from '../contexts/PracticeContext'
import {
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  FormGroup,
  TextareaAutosize,
} from '@material-ui/core'
import {
  assembleElementFormId,
  getNestedElement,
  getElementPath,
  getValuesForOptionIds,
  applyDefaultValuesRecursively,
} from '../utils/formHelpers'
import { FormContent } from './FormContent'
import DatePicker from './DatePicker'
import { recallElementOptions } from './RecallElementOptions'
import TextInputGroup from './DocumentTemplate/Elements/TextInputGroup'
import {
  renderInputLabel,
  replaceContent,
  validateInput,
} from './DocumentTemplate/Elements/helpers'
import TextInput from './DocumentTemplate/Elements/TextInput'

import CountryInput from './DocumentTemplate/Elements/CountryInput'

import { useRecallElement } from '../contexts/RecallElementProvider'
import {
  filterRecallToOptions,
  isRecallElement,
  isRecallToElement,
  isRecallTypeElement,
} from '../utils/recallHelpers'
import DynamicSelectOne from './DocumentTemplate/Elements/DynamicSelectOne'

const RECALL_ELEMENT_ID =
  'document_templates.0.id_df2507ab_19da_4338_ac07_278818d9f2c7'

interface CSSProps {
  align?: string
  size?: string
  children?: React.ReactNode
}

const HeadingElement = styled.div<CSSProps>`
  text-align: ${({ align }) => align};
  color: ${colors.blue};

  h1 {
    font-size: 30px;
    font-weight: 400;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    border-bottom: 2px solid ${colors.lightGrey};
  }
  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    border-bottom: 2px solid ${colors.lightGrey};
  }
  h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    border-bottom: 2px solid ${colors.lightGrey};
  }
  h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    border-bottom: 2px solid ${colors.lightGrey};
  }
`

interface HeadingProps {
  activeLang: string
  element: HeadingType
}

const Heading = ({ activeLang, element: { content, style } }: HeadingProps) => {
  const { headingSize, ...rest } = style

  return (
    <HeadingElement>
      {React.createElement('h' + headingSize, rest, content[activeLang])}
    </HeadingElement>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`
const Col = styled.div<{ children: React.ReactNode }>`
  text-align: left;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 10px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0;
  }
`

interface GroupProps {
  element: GroupType
  activeLang: string
  templateId: string
}

const Group = ({ activeLang, element, templateId }: GroupProps) => (
  <Row>
    {element.children.map((child) => (
      <Col key={child.id}>
        <FormElement
          activeLang={activeLang}
          element={child}
          templateId={templateId}
        />
      </Col>
    ))}
  </Row>
)

const Ol = styled('ol')`
  font-size: 18px;
  margin: 20px 0;
  padding-inline-start: 20px;
`
const Ul = styled('ul')`
  list-style-type: disc;
  font-size: 18px;
  margin: 20px 0;
  padding-inline-start: 20px;
`
const Li = styled('li')`
  margin: 5px 0;
`

interface ListProps {
  element: ListType
  activeLang: string
}

const List = ({ activeLang, element }: ListProps) => {
  const patient = getPatient(useFormikContext().values)
  const practice = useContext(PracticeContext)
  const items = element.items.map((item) => (
    <Li key={item.id}>
      {replaceContent(item.content[activeLang], activeLang, patient, practice)}
    </Li>
  ))
  return element.style.order === 'ordered' ? <Ol>{items}</Ol> : <Ul>{items}</Ul>
}

interface TextProps {
  element: TextType
  activeLang: string
}
const Text = ({ activeLang, element }: TextProps) => {
  const patient = getPatient(useFormikContext().values)
  const practice = useContext(PracticeContext)
  let content = replaceContent(
    element.content[activeLang],
    activeLang,
    patient,
    practice,
  )
  return <div dangerouslySetInnerHTML={{ __html: content } as any} />
}

interface ParagraphProps {
  element: ParagraphType
  activeLang: string
}
const Paragraph = ({ activeLang, element }: ParagraphProps) => {
  const patient = getPatient(useFormikContext().values)
  const practice = useContext(PracticeContext)
  let content = replaceContent(
    element.content[activeLang],
    activeLang,
    patient,
    practice,
  )
  return <p dangerouslySetInnerHTML={{ __html: content } as any} />
}

interface HrProps {
  line?: string
}

export const StyledHr = styled.hr<HrProps>`
  margin: 50px 0;
  background-color: ${colors.grey};
  width: ${({ line }) => (line === 'short' ? '50%' : '100%')};
  margin-left: ${({ line }) => (line === 'short' ? 'auto' : 'initial')};
  margin-right: ${({ line }) => (line === 'short' ? 'auto' : 'initial')};
`
const StyledDots = styled.div`
  margin: 50px auto;
  text-align: center;

  &:before {
    color: ${colors.grey};
    content: '• • •';
    font-family: serif;
    font-size: 20px;
    letter-spacing: 1.5em;
    padding-left: 2em;
  }
`

interface SeparatorProps {
  element: SeparatorType
}
const Separator = ({ element }: SeparatorProps) =>
  element.style && element.style.line === 'dots' ? (
    <StyledDots />
  ) : (
    <StyledHr {...element.style} />
  )

const DivWithSpace = styled.div<CSSProps>`
  width: 100%;
  height: 0;
  padding-bottom: ${({ size }) =>
    size === 'l' ? '12%' : size === 'm' ? '6%' : '3%'};
`

interface SpacerProps {
  element: SpacerType
}
export const Spacer = ({ element }: SpacerProps) => (
  <DivWithSpace {...element.style} />
)

const ImgWrap = styled.div<CSSProps>`
  margin-left: ${({ align }) => (align === 'left' ? 'unset' : 'auto')};
  margin-right: ${({ align }) => (align === 'center' ? 'auto' : 'unset')};
  width: ${({ size }) =>
    size === 's' ? '33%' : size === 'm' ? '50%' : '100%'};
`

const Img = styled.img<{ alt?: string; src: string }>`
  max-width: 100%;
`

interface ImageProps {
  element: ImageType
}

const Image = ({ element }: ImageProps) => (
  <ImgWrap {...element.style}>
    {element.source === '{{praxis_logo}}' ? (
      <PracticeLogo originalSize desktopOnly />
    ) : (
      <Img alt={element.source} src={element.source} />
    )}
  </ImgWrap>
)

export const InputContainer = styled.div<{
  hasLabel?: boolean
  children: React.ReactNode
  id?: string
}>`
  padding: ${({ hasLabel }) => (hasLabel ? '1px 0 1%' : '5px 0 4%')};
`

export const InputField = styled(TextField)<{
  hasLabel?: boolean
  width?: string
}>`
  height: ${({ hasLabel }) => (hasLabel ? 'auto' : '70px')};
  width: ${({ width }) => width ?? 'auto'};

  input {
    color: ${colors.blue};
    font-size: 20px;
    border: 1px solid ${colors.blue};
    border-radius: 5px;
    padding: 12px 30px 12px 8px;

    &::placeholder {
      color: ${colors.black};
    }

    &:disabled {
      color: ${colors.grey};
      border: 1px solid ${colors.lightGrey};
      background-color: ${colors.lightGrey};
    }
  }
`

const FormErrorText = (props: any) => {
  return <FormHelperText error>{props.children}</FormHelperText>
}

interface TextAreaInputProps {
  noBorder?: boolean
  size?: string
  error?: string
}
const TextAreaInput = styled(TextareaAutosize)<TextAreaInputProps>`
  color: ${colors.blue};
  outline-width: 0;
  border-radius: 5px;
  border: ${({ noBorder, error }) =>
    noBorder
      ? 'none'
      : error
      ? `1px solid ${colors.red}`
      : `1px solid ${colors.blue}`};
  width: ${({ size }) =>
    size === 'l' ? '100%' : size === 'm' ? '50%' : '33%'};
  padding: 8px 10px;
  font-size: 20px;

  &::placeholder {
    color: ${colors.grey};
  }
`

interface TextAreaProps {
  element: TextareaType
  activeLang: string
}
const TextArea = ({ activeLang, element }: TextAreaProps) => {
  const patient = getPatient(useFormikContext().values)
  const practice = useContext(PracticeContext)
  let placeholder: string = ''
  if (element.placeholder && element.placeholder[activeLang]) {
    placeholder = replaceContent(
      element.placeholder[activeLang],
      activeLang,
      patient,
      practice,
    )
  }

  return (
    <InputContainer id={element.id}>
      {renderInputLabel(element, activeLang)}
      <FastField
        key={`${element.id}-${activeLang}`}
        name={element.id}
        validate={(value: string) => validateInput(value, element, activeLang)}>
        {({ field, meta }: FieldProps) => (
          <TextAreaInput
            placeholder={placeholder}
            error={meta.touched && meta.error ? 'error' : ''}
            rows={4}
            rowsMax={4}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={element.id}
            value={field.value || ''}
            autoComplete="off"
            autoSave="off"
            autoCorrect="off"
            {...element.style}
          />
        )}
      </FastField>
      <ErrorMessage name={element.id} component={FormErrorText} />
    </InputContainer>
  )
}

const Options = styled.div<{
  arrangement: ArrangementType
  children: React.ReactNode
}>`
  display: flex;
  flex-direction: ${({ arrangement }) =>
    arrangement === 'horizontal' ? 'row' : 'column'};
  align-items: ${({ arrangement }) =>
    arrangement === 'horizontal' ? 'center' : 'flex-start'};
  justify-content: flex-start;
`

const Children = styled.div<{ children: React.ReactNode }>`
  padding-left: 20px;
  border-left: 8px solid ${colors.lightGrey};
`

interface SelectOneProps {
  element: SelectOneType | YesNoType | RecallType | DynamicSelectOneType
  activeLang: string
  templateId: string
}

export const SelectOne = (props: SelectOneProps) => {
  const { activeLang, element, templateId } = props
  const { values }: FormikContextType<any> = useFormikContext()
  const { setValues, setFieldValue } = useFormikContext()
  const { recall_elements } = useContext(PracticeContext)
  const { clearRecall, setPatientType } = useRecallElement()
  const options = element.id.includes(RECALL_ELEMENT_ID)
    ? recallElementOptions(element.options, recall_elements)
    : element.options

  const handleChange = (event: any) => {
    const templateIdInt = parseInt(templateId)
    const optionValues = getValuesForOptionIds(
      element.options,
      event.target.value,
    )
    const uncheckedOptions = element.options.filter(
      (option) => option.id !== event.target.value,
    )
    const resetValues = applyDefaultValuesRecursively(uncheckedOptions)

    setValues({
      ...values,
      document_templates: [
        ...values.document_templates.slice(0, templateIdInt),
        {
          ...values.document_templates[templateIdInt],
          ...resetValues,
          ...optionValues,
        },
        ...values.document_templates.slice(
          templateIdInt + 1,
          values.document_templates.length,
        ),
      ],
    })

    const selectedOption: any = element.options.find(
      (option) => option.id === event.target.value,
    )
    setFieldValue(event.target.name, event.target.value)

    if (isRecallElement(element) && selectedOption.ds4FieldValue !== true) {
      clearRecall()
    }

    if (isRecallToElement(element)) {
      setPatientType(selectedOption.ds4FieldValue)
    } else {
      if (
        Boolean(selectedOption.ds4FieldValue) &&
        selectedOption.ds4FieldValue !== true
      ) {
        setFieldValue(element.id, selectedOption.ds4FieldValue)
      }
    }
  }

  return (
    <Field
      key={`${element.id}-${activeLang}`}
      name={element.id}
      validate={(value: string) => validateInput(value, element, activeLang)}>
      {({ field, form }: FieldProps) => {
        return (
          <InputContainer id={element.id}>
            {renderInputLabel(element, activeLang)}
            <RadioGroup
              aria-label={(element.label && element.label[activeLang]) || ''}
              name={element.id}
              value={field.value || ''}
              onChange={handleChange}
              onBlur={field.onBlur}>
              <Options
                arrangement={
                  (element.style && element.style.optionsArrangement) ||
                  'horizontal'
                }>
                {options.map((option: RadioButtonType) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    control={<Radio color="primary" />}
                    label={(option.label && option.label[activeLang]) || ''}
                    checked={!!field.value && option.id.includes(field.value)}
                  />
                ))}
              </Options>
            </RadioGroup>
            {element.options.map(
              (option: RadioButtonType) =>
                field.value === option.id && (
                  <Children key={option.id}>
                    {option.children.map((child: ATNValueType) => (
                      <FormElement
                        activeLang={activeLang}
                        element={child}
                        templateId={templateId}
                        key={child.id}
                      />
                    ))}
                  </Children>
                ),
            )}
            <ErrorMessage name={element.id} component={FormErrorText} />
          </InputContainer>
        )
      }}
    </Field>
  )
}

interface SelectMultipleProps {
  element: SelectMultipleType
  activeLang: string
  templateId: string
}

export const SelectMultiple = (props: SelectMultipleProps) => {
  const { activeLang, element, templateId } = props
  const elementPath = getElementPath(element.id)
  const { values }: FormikContextType<any> = useFormikContext()
  const { recall_elements } = useContext(PracticeContext)
  const { setValues, setFieldValue } = useFormikContext()
  const { setRecallType } = useRecallElement()
  const [field] = useField(element.id)

  const elementOptions = isRecallTypeElement(element)
    ? filterRecallToOptions(element.options, recall_elements)
    : element.options

  const handleChange = (event: any) => {
    const templateIdInt = parseInt(templateId)
    if (element.id.includes('consents')) {
      setFieldValue(element.id, field.value.length === 0 ? ['yes'] : [])
      return
    }

    let tickedBoxesIds: string[]
    if (!field.value.includes(event.target.value)) {
      tickedBoxesIds = [...field.value, event.target.value]
    } else {
      tickedBoxesIds = field.value.filter(
        (id: any) => id !== event.target.value,
      )
    }

    const optionValues = getValuesForOptionIds(element.options, tickedBoxesIds)

    let resetValues: { [id: string]: string } = {}

    if (!event.target?.checked) {
      const selectedCheckBox = element.options.find(
        (option) => option.id === event.target.value,
      )
      resetValues = applyDefaultValuesRecursively(selectedCheckBox?.children)
    }

    setValues({
      ...values,
      document_templates: [
        ...values.document_templates.slice(0, templateIdInt),
        {
          ...values.document_templates[templateIdInt],
          ...resetValues,
          ...optionValues,
        },
        ...values.document_templates.slice(
          templateIdInt + 1,
          values.document_templates.length,
        ),
      ],
    })
    setFieldValue(element.id, tickedBoxesIds)

    if (isRecallTypeElement(element)) {
      const selectedOption = element.options.find(
        (option: any) => option?.id === event.target.value,
      )
      //@ts-ignore
      setRecallType(selectedOption.ds4FieldValue)
    }
  }

  return (
    <InputContainer id={element.id}>
      {renderInputLabel(element, activeLang)}
      <FormControl component="fieldset" style={{ width: '100%' }}>
        <FormGroup>
          {elementOptions &&
            elementOptions.map((option: CheckboxType, i: number) => {
              return (
                <div key={option.id}>
                  <Field
                    key={`${element.id}-${activeLang}`}
                    type="checkbox"
                    validate={(value: string) =>
                      validateInput(value, element, activeLang)
                    }
                    name={element.id}
                    value={option.id}>
                    {({ field, form }: FieldProps) => (
                      <>
                        <FormControlLabel
                          onChange={handleChange}
                          checked={field.checked}
                          control={
                            <Checkbox
                              color={'primary'}
                              value={option.id}
                              name={element.id}
                            />
                          }
                          label={option.label && option.label[activeLang]}
                        />
                        {getNestedElement(form.values, elementPath) &&
                          getNestedElement(form.values, elementPath).includes(
                            option.id,
                          ) && (
                            <FormContent
                              activeLang={activeLang}
                              atn={option.children}
                              templateId={templateId}
                              key={`${option.id}-children`}
                            />
                          )}
                      </>
                    )}
                  </Field>
                </div>
              )
            })}
        </FormGroup>
      </FormControl>
      <ErrorMessage name={element.id} component={FormErrorText} />
    </InputContainer>
  )
}

// const Sign = styled.div`
//   width: 300px;
//   margin: 35px 0;
//   padding-bottom: 10px;
// `
// const SignField = styled.div`
//   cursor: pointer;
//   border: 4px dashed ${colors.red};
//   padding: 20px;
//   text-align: left;
// `
// const SignatureCaption = styled('p')`
//   font-style: italic;
//   font-size: 18px;
//   margin: 10px 20px;
//   color: ${colors.red};
// `
// const SignTitle = styled('p')`
//   font-size: 20px;
//   margin-bottom: 10px;
//   color: ${colors.red};
// `
// const SignInfoLabel = styled('p')`
//   margin-top: 10px;
//   font-size: 16px;
//   color: ${colors.red};
// `

// interface SignatureProps {
//   element: SignatureType
//   activeLang: string
// }

// TODO: In future we can add signature support with ana ability to sign with mouse or touch
// const Signature = ({ activeLang, element }: SignatureProps) => (
//   <Sign>
//     <SignField>
//       <SignTitle>Unterschrift</SignTitle>
//       <SignInfoLabel>
//         Unterschriften werden in Anamnese@Home leider nicht unterstützt.
//       </SignInfoLabel>
//     </SignField>
//     <SignatureCaption>{element.caption[activeLang]}</SignatureCaption>
//   </Sign>
// )

const VideoWrap = styled.div<CSSProps>`
  text-align: ${({ align }) => align};
`
interface VideoProps {
  element: VideoType
}
const Video = ({ element }: VideoProps) => (
  <VideoWrap {...element.style}>
    <video
      controls={true}
      poster={element.placeholderSource}
      src={element.source}
      style={{ maxWidth: `100%` }}
    />
  </VideoWrap>
)

type PropsType = {
  activeLang: string
  element: ATNValueType
  templateId: string
}

const dsWinDates = ['geburtsdatum', 'versicherter_geburtsdatum']

export class FormElement extends PureComponent<PropsType> {
  render() {
    const { activeLang, templateId } = this.props
    const elementId = assembleElementFormId(templateId, this.props.element.id)
    let element = { ...this.props.element, id: elementId }

    switch (element.type) {
      case 'heading':
        return <Heading activeLang={activeLang} element={element} />
      case 'group':
        return (
          <Group
            activeLang={activeLang}
            element={element}
            templateId={templateId}
          />
        )
      case 'list':
        return <List activeLang={activeLang} element={element} />
      case 'text':
        return <Text activeLang={activeLang} element={element} />
      case 'paragraph':
        return <Paragraph activeLang={activeLang} element={element} />
      case 'separator':
        return <Separator element={element} />
      case 'spacer':
        return <Spacer element={element} />
      case 'image':
        return <Image element={element} />
      case 'video':
        return <Video element={element} />
      case 'textInputGroup':
        return <TextInputGroup element={element} activeLang={activeLang} />
      case 'textInput':
        const { dsWinFieldName, id, label, required, ds4FieldName } = element
        const fieldName = ds4FieldName || dsWinFieldName
        if (
          ds4FieldName &&
          ['versicherter_land', 'land'].includes(ds4FieldName)
        ) {
          return <CountryInput activeLang={activeLang} element={element} />
        }
        if (fieldName && dsWinDates.includes(fieldName)) {
          return <DatePicker name={id} label={label} required={required} />
        }
        return <TextInput activeLang={activeLang} element={element} />
      case 'textAreaInput':
        return <TextArea activeLang={activeLang} element={element} />
      case 'recall':
      case 'yesNo':
        return (
          <SelectOne
            activeLang={activeLang}
            templateId={templateId}
            element={{
              ...element,
              style: { ...element.style, optionsArrangement: 'horizontal' },
            }}
          />
        )
      case 'selectOne':
        return (
          <SelectOne
            activeLang={activeLang}
            element={element}
            templateId={templateId}
          />
        )
      case 'selectMultiple':
        return (
          <SelectMultiple
            activeLang={activeLang}
            element={element}
            templateId={templateId}
          />
        )
      case 'dynamicSelectOne':
        return (
          <DynamicSelectOne
            activeLang={activeLang}
            element={element}
            templateId={templateId}
          />
        )
      default:
        return null
    }
  }
}
