import React, { useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { AnamnesisFlow } from './types/AnamnesisFlow'
import { PublicLinkParams } from './types/Params'
import { DocumentFlow } from './pages/DocumentFlow/index'
import { DocumentFlowContext } from './contexts/DocumentFlowContext'
import { PracticeContext } from './contexts/PracticeContext'
import { templateServer } from './config'
import { ThankYou } from './pages/ThankYou'
import { useParams } from 'react-router-dom'
import { Welcome } from './pages/Welcome'
import { Fallback } from './pages/Fallback'
import { Loading } from './pages/Loading'
import { getLanguagesSubset } from './utils/languageHelpers'
import { LanguageContext } from './contexts/LanguageContext'

const PublicLink = () => {
  const { token } = useParams<PublicLinkParams>()
  const { setPractice, loaded } = useContext(PracticeContext)
  const { stage, setTemplates, setIsLoading } = useContext(DocumentFlowContext)
  const { setSupportedLanguages } = useContext(LanguageContext)

  const [{ loading, error }, requestData] = useAxios<AnamnesisFlow>(
    `${templateServer}/${token}`,
    {
      manual: true,
    },
  )

  useEffect(() => {
    setIsLoading(true)
    requestData()
      .then((result) => {
        setPractice(result.data.practice)
        setTemplates(result.data.document_templates)
        setSupportedLanguages(
          getLanguagesSubset(result.data.document_templates),
        )
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }, [
    requestData,
    setPractice,
    setTemplates,
    setIsLoading,
    setSupportedLanguages,
  ])

  // TODO optimise re-rendering
  // console.log('rendering')

  if (loading) return <Loading />
  if (error) return <Fallback error />

  if (loaded) {
    switch (stage) {
      case 'welcome':
        return <Welcome />
      case 'documentFlow':
        return <DocumentFlow extraSubmissionData={{ token }} />
      case 'thankYou':
        return <ThankYou />
    }
  } else {
    return <Loading />
  }
}

export default PublicLink
