import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { colors, theme } from '../utils/theme'
import { Container, Link } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { imprintUrl } from '../config'
import { PracticeContext } from '../contexts/PracticeContext'

export const footerHeight = 60

const Wrapper = styled.div`
  width: 100%;
  min-height: ${footerHeight}px;
  line-height: ${footerHeight}px;
  background-color: ${colors.lightGrey};
  color: ${colors.grey};
  font-size: 14px;
  font-weight: 400;

  ${theme.breakpoints.down('sm')} {
    line-height: 45px;
    font-size: 16px;
  }
`

const IconWrapper = styled.span`
  svg {
    width: 16px;
    margin: 0 5px -3px;
    height: 16px;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`

const PracticeContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  line-height: initial;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    line-height: inherit;
    flex-wrap: inherit;
    text-align: center;
  }
`

const LegalContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`

const Divider = styled.span`
  ::before {
    content: '|';
    margin: 0 5px;
  }

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

export const Footer = () => {
  const { name, address1, address2, zip_code, city, phone, email } = useContext(
    PracticeContext,
  )

  return (
    <Wrapper>
      <Container maxWidth="lg">
        <Content>
          <PracticeContent>
            <span>
              {name && (
                <>
                  {name}
                  <Divider />
                </>
              )}
            </span>
            <span>
              {address1 && address1}
              {address2 && ' ' + address2}
              {zip_code && ', ' + zip_code} {city}
            </span>
            {phone && (
              <span>
                <Divider />
                <IconWrapper>
                  <PhoneIcon />
                </IconWrapper>
                <Link href={`tel:${phone}`}>{phone}</Link>
              </span>
            )}
            {email && (
              <span>
                <Divider />
                <IconWrapper>
                  <EmailIcon />
                </IconWrapper>
                <Link href={`mailto:${email}`}>{email}</Link>
              </span>
            )}
          </PracticeContent>
          <LegalContent>
            <Link target="_blank" href={'/datenschutz'}>
              <FormattedMessage id="footer.privacyPolicy" />
            </Link>
            <Divider />
            <Link target="_blank" href={imprintUrl}>
              <FormattedMessage id="footer.imprint" />
            </Link>
          </LegalContent>
        </Content>
      </Container>
    </Wrapper>
  )
}
