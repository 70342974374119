// @ts-nocheck

import { some } from 'lodash'

const recallOptionsMapping = {
  brief: 'recall_letter',
  sms: 'recall_sms',
  email: 'recall_email',
  telefon: 'recall_phone',
}

export const isRecallElement = (element) => element.type === 'recall'

export const isRecallTypeElement = (element) =>
  element.ds4FieldName === 'recallType'

export const isDynamicSelectOneElement = (element) =>
  element.type === 'dynamicSelectOnce'

export const isRecallToElement = (element) =>
  element.ds4FieldName === 'recallTo'

export const isRecallToEmail = (element) =>
  element.ds4FieldName === 'recallToEmail'

export const isRecallToPhone = (element) =>
  element.ds4FieldName === 'recallToPhone'

export const isRecallToEmailOrPhoneElement = (element) =>
  isRecallToEmail(element) || isRecallToPhone(element)

export const isPatientTypeSelected = (patientType) => patientType === 'patient'

export const isElementShownByRecallType = (
  element,
  recallType,
  patientType,
) => {
  if (!isPatientTypeSelected(patientType)) {
    return false
  }
  if (isRecallToEmail(element)) {
    return recallType.includes('email')
  }
  if (isRecallToPhone(element)) {
    return recallType.includes('telefon') || recallType.includes('sms')
  }

  return false
}

const searchInATNArray = (collection, ds4FieldName) => {
  let result = null
  // eslint-disable-next-line no-return-assign
  some(
    collection,
    (item) => (result = findATNElementByDs4FieldName(item, ds4FieldName)),
  )
  return result
}

const findATNElementByDs4FieldName = (schema, ds4FieldName) => {
  if (!schema || !ds4FieldName) return null
  if (schema.ds4FieldName === ds4FieldName) return schema

  return (
    searchInATNArray(schema.children, ds4FieldName) ||
    searchInATNArray(schema.options, ds4FieldName) ||
    (Array.isArray(schema) ? searchInATNArray(schema, ds4FieldName) : null)
  )
}

export const getElementIdInATN = (
  elementType: 'telefonnummern' | 'emailadressen',
  atn,
) => {
  const recallElement = findATNElementByDs4FieldName(atn, elementType)
  return recallElement ? recallElement.id : null
}

export const filterRecallToOptions = (elementOptions, recallOptions) => {
  return elementOptions.filter((option) =>
    Boolean(recallOptions[recallOptionsMapping[option.ds4FieldValue]]),
  )
}
