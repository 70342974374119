import { DocumentFlowStage } from './types/DocumentFlow'

export const templateServer = process.env.REACT_APP_TEMPLATE_SERVER

export const submissionServer = process.env.REACT_APP_SUBMISSION_SERVER

export const athenaUrl = 'https://dampsoft.de/athena'
export const privacyPolicyUrl = 'https://www.dampsoft.de/datenschutz/'
export const imprintUrl = 'https://www.dampsoft.de/impressum/'

/*
 / ########################################
 / ####### DEV MODE - Testing Only ########
 / ########################################
*/

export const devMode = false

export const devStage = 'documentFlow' as DocumentFlowStage
export const devStepIndex = 0
export const fillAnamneseTemplate = true
export const fillDatenschutzTemplate = true
export const fillTestTemplate = true
export const fillConsents = true
