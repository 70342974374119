import React, { useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { AnamnesisSubmission } from '../../types/AnamnesisSubmission'
import { Container, Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { FormError } from '../../components/FormError'
import { FormFocusError } from '../../components/FormFocusError'
import { getFixedSteps } from './fixedSteps'
import { Loading } from '../Loading'
import { StepButtons } from '../../components/StepButtons'
import { StepContent } from '../../components/StepContent'
import { Stepper } from '../../components/Stepper'
import { submissionServer } from '../../config'
import { useIntl } from 'react-intl'
import {
  DocumentFlowContext,
  getSteps,
} from '../../contexts/DocumentFlowContext'
import {
  assembleSubmission,
  generateInitialFormValues,
  generateInitialConsentValues,
  generateInitialFixedStepValues,
} from '../../utils/formHelpers'

interface Props {
  extraSubmissionData: { [key in string]?: {} }
}

export const DocumentFlow = ({ extraSubmissionData }: Props) => {
  const { formatMessage } = useIntl()
  const { templates, setStage, stepIndex, setStepIndex } = useContext(
    DocumentFlowContext,
  )

  const fixedSteps = getFixedSteps(formatMessage)
  const steps = getSteps(fixedSteps, templates)
  let initialPatientValues = generateInitialFixedStepValues(fixedSteps[0])

  const [{ loading, error }, submitForm] = useAxios<AnamnesisSubmission>(
    {
      url: `${submissionServer}`,
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    },
    {
      manual: true,
    },
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  if (loading) return <Loading />

  return (
    <>
      <Formik
        initialValues={{
          ...initialPatientValues,
          ...generateInitialFormValues(templates),
          ...generateInitialConsentValues(templates),
        }}
        validationSchema={steps[0].validationSchema}
        onSubmit={(values, formikHelpers) => {
          if (stepIndex < steps.length - 1) {
            setStepIndex(stepIndex + 1)
            formikHelpers.setTouched({})
            formikHelpers.setSubmitting(false)
          } else {
            const data = {
              ...assembleSubmission(values, templates),
              ...extraSubmissionData,
            }
            submitForm({ data }).then(() => setStage('thankYou'))
          }
        }}>
        <Form>
          <Container maxWidth="lg">
            <Stepper steps={steps} />
          </Container>
          {error && <FormError />}
          <Grid container direction="column">
            <StepContent stepIndex={stepIndex} steps={steps} />
          </Grid>
          <Container maxWidth="md">
            <StepButtons
              stepIndex={stepIndex}
              steps={steps}
              setStepIndex={setStepIndex}
            />
          </Container>
          <FormFocusError />
        </Form>
      </Formik>
    </>
  )
}
