import React, { useCallback } from 'react'

type RecallElementContextType = {
  recallType: Array<string>
  patientType: string | null
  setRecallType: (type: string) => void
  clearRecall: () => void
  setPatientType: (type: string) => void
} | null

export const RecallElementContext = React.createContext<
  RecallElementContextType
>(null)

export const RecallElementProvider: React.FC = ({ children }) => {
  const [recallType, setRecallType] = React.useState<Array<string>>([])
  const [patientType, setPatientType] = React.useState<string | null>(null)

  const handleSetRecallType = useCallback(
    (type: string) => {
      if (recallType.includes(type)) {
        setRecallType((prev) => prev.filter((item) => item !== type))
      } else {
        setRecallType((prev) => [...prev, type])
      }
    },
    [recallType],
  )

  const clearRecall = useCallback(() => {
    setRecallType([])
    setPatientType(null)
  }, [])

  return (
    <RecallElementContext.Provider
      value={{
        recallType,
        patientType,
        setRecallType: handleSetRecallType,
        clearRecall,
        setPatientType,
      }}>
      {children}
    </RecallElementContext.Provider>
  )
}

export default RecallElementProvider

export const useRecallElement = () => {
  const context = React.useContext(RecallElementContext)
  if (!context) {
    throw new Error(
      'useRecallElement must be used within a RecallElementProvider',
    )
  }
  return context
}
