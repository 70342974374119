import React from 'react'
import { useIntl } from 'react-intl'
import { ContentType } from '../types/ATNTypes'
import { FormattedMessage } from 'react-intl'
import Label from '../styled/Label'
import RequiredStar from '../styled/RequiredStar'

interface InputLabelProps {
  label?: string | ContentType
  required?: boolean
}

const InputLabel = ({ label, required }: InputLabelProps) => {
  const { locale } = useIntl()

  if (!label) return null

  return (
    <Label>
      {typeof label === 'string' ? (
        <FormattedMessage id={label} />
      ) : label[locale] ? (
        label[locale]
      ) : null}
      {required && <RequiredStar />}
    </Label>
  )
}

export default InputLabel
