import { object, string } from 'yup'
import { PatientInterface } from '../../types/Patient'
import { StepInterface } from '../../types/Step'
import { devMode } from '../../config'
import { MessageDescriptor } from 'react-intl'
import { PrimitiveType } from '../../types/PrimitiveType'

export const getFixedSteps = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType> | undefined,
  ) => string,
): StepInterface[] => [
  {
    id: 'patient',
    title: formatMessage({ id: 'personalData.title' }),
    initialValues: {
      first_name: devMode ? 'Carl' : '',
      last_name: devMode ? 'Dau' : '',
      date_of_birth: devMode ? '1990-10-10' : null,
    } as PatientInterface,
    validationSchema: object().shape({
      patient: object().shape({
        first_name: string()
          .min(2, formatMessage({ id: 'error.textTooShort' }))
          .max(50, formatMessage({ id: 'error.textTooLong' }))
          .required(formatMessage({ id: 'error.requiredTextInput' })),
        last_name: string()
          .min(2, formatMessage({ id: 'error.textTooShort' }))
          .max(50, formatMessage({ id: 'error.textTooLong' }))
          .required(formatMessage({ id: 'error.requiredTextInput' })),
      }),
    }),
  },
  {
    id: 'summary',
    title: formatMessage({ id: 'summary.title' }),
  },
]
