import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as serviceWorker from './serviceWorker'
import IndividualLink from './IndividualLink'
import PublicLink from './PublicLink'
import React from 'react'
import ReactDOM from 'react-dom'
import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { DocumentFlowProvider } from './contexts/DocumentFlowProvider'
import { Fallback } from './pages/Fallback'
import { GlobalStyle, theme } from './utils/theme'
import { LanguageProvider } from './contexts/LanguageProvider'
import { MuiThemeProvider } from '@material-ui/core'
import { PracticeProvider } from './contexts/PracticeProvider'
import { InvitationDetailsProvider } from './contexts/InvitationDetailsProvider'
import { Footer } from './components/Footer'
import { AppContent } from './components/AppContent'
import { Header } from './components/Header'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/de'
import { Error } from './pages/Error'

const config = {
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY || '',
  environment: process.env.NODE_ENV,
  revision: process.env.REACT_APP_REVISION,
}

const honeybadger = Honeybadger.configure(config)

ReactDOM.render(
  <ErrorBoundary ErrorComponent={Error} honeybadger={honeybadger}>
    <LanguageProvider>
      <PracticeProvider>
        <DocumentFlowProvider>
          <InvitationDetailsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="de">
              <Router>
                <GlobalStyle />
                <MuiThemeProvider theme={theme}>
                  <Header />
                  <AppContent>
                    <Switch>
                      <Route exact path="/">
                        <Fallback />
                      </Route>
                      <Route path="/datenschutz" children={<PrivacyPolicy />} />
                      <Route
                        path="/invitation/:invitationToken"
                        children={<IndividualLink />}
                      />
                      <Route path="/:token" children={<PublicLink />} />
                    </Switch>
                  </AppContent>
                  <Footer />
                </MuiThemeProvider>
              </Router>
            </MuiPickersUtilsProvider>
          </InvitationDetailsProvider>
        </DocumentFlowProvider>
      </PracticeProvider>
    </LanguageProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
