import React, { useContext } from 'react'
import styled from 'styled-components'
import { colors } from '../utils/theme'
import { Container, Grid } from '@material-ui/core'
import { DampsoftLogo } from './DampsoftLogo'
import { LanguagePicker } from './LanguagePicker'
import { PracticeLogo } from './PracticeLogo'
import { StepProgressIndicator } from './StepProgressIndicator'
import { PracticeContext } from '../contexts/PracticeContext'
import { DocumentFlowContext } from '../contexts/DocumentFlowContext'

export const headerHeight = 60

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${headerHeight}px;
  line-height: ${headerHeight}px;
  background-color: ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
`

const Content = styled.div`
  display: flex;
  height: ${headerHeight}px;

  .MuiGrid-item {
    display: flex;
    justify-content: center;

    &:first-of-type {
      justify-content: flex-start;
    }

    &:last-of-type {
      justify-content: flex-end;
    }
  }
`

export const Header = () => {
  const { isLoading } = useContext(DocumentFlowContext)
  const { logo_url, loaded } = useContext(PracticeContext)

  const renderLogo = () => {
    if (isLoading) return null
    if (loaded && !logo_url) return null
    if (logo_url) return <PracticeLogo />
    return <DampsoftLogo />
  }

  return (
    <Wrapper>
      <Container maxWidth="lg">
        <Content>
          <Grid item xs={8} md={3}>
            {renderLogo()}
          </Grid>
          <Grid item md={6}>
            <StepProgressIndicator />
          </Grid>
          <Grid item xs={4} md={3}>
            <LanguagePicker />
          </Grid>
        </Content>
      </Container>
    </Wrapper>
  )
}
