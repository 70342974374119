import React, { useState } from 'react'
import { DocumentFlowContext } from './DocumentFlowContext'
import {
  DocumentFlowStage,
  DocumentTemplateInterface,
} from '../types/DocumentFlow'
import { devMode, devStepIndex, devStage } from '../config'

export const DocumentFlowProvider = (props: any) => {
  const setStage = (stage: DocumentFlowStage) => {
    updateDocumentFlowState((prevState) => {
      const newState = { ...prevState }
      return Object.assign(newState, {
        stage,
      })
    })
  }
  const setTemplates = (templates: DocumentTemplateInterface[]) => {
    updateDocumentFlowState((prevState) => {
      const newState = { ...prevState }
      return Object.assign(newState, {
        templates,
      })
    })
  }
  const setStepIndex = (stepIndex: number) => {
    updateDocumentFlowState((prevState) => {
      const newState = { ...prevState }
      return Object.assign(newState, {
        stepIndex,
      })
    })
  }
  const setIsLoading = (isLoading: boolean) => {
    updateDocumentFlowState((prevState) => {
      const newState = { ...prevState }
      return Object.assign(newState, {
        isLoading,
      })
    })
  }

  const documentFlowState = {
    stepIndex: devMode ? devStepIndex : 0,
    stage: devMode ? devStage : ('welcome' as DocumentFlowStage),
    templates: [] as DocumentTemplateInterface[],
    isLoading: false,
    setStepIndex,
    setStage,
    setTemplates,
    setIsLoading,
  }

  const [documentFlow, updateDocumentFlowState] = useState(documentFlowState)

  return (
    <DocumentFlowContext.Provider value={documentFlow}>
      {props.children}
    </DocumentFlowContext.Provider>
  )
}
