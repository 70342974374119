import styled from 'styled-components'
import React from 'react'
import { Link } from '@material-ui/core'
import { athenaUrl } from '../config'

interface Props {
  alt?: string
  src: string
  originalSize?: boolean
}

const Image = styled.img<Props>`
  max-height: 80px;
`

export const AthenaLogo = () => {
  return (
    <Link href={athenaUrl} target="_blank">
      <Image alt="dampsoft-logo" src="athena_logo.png" />
    </Link>
  )
}
