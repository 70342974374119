import React from 'react'
import styled from 'styled-components'
import { Card } from '../components/Card'
import { Grid, Link, Typography } from '@material-ui/core'
import { theme } from '../utils/theme'

const Wrapper = styled.div`
  padding-top: 20px;

  ${theme.breakpoints.down('sm')} {
    padding-top: 10px;
  }
`

export const PrivacyPolicy = () => (
  <Wrapper>
    <Card>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">Datenschutzhinweise</Typography>
          <Typography variant="h2">Gegenstand</Typography>
          <p>
            Nachfolgend möchten wir Sie darüber informieren, welche
            personenbezogenen Daten bei Ihrem Besuch unserer Website und der
            Nutzung unserer dortigen Angebote verarbeitet werden.
          </p>
          <p>
            Personenbezogene Daten (nachfolgend „Daten“) sind alle Daten, die
            auf Sie persönlich beziehbar sind, z.B. Ihr Name, Ihre
            E-Mail-Adresse und Ihre Nutzung unserer Website.
          </p>
          <Typography variant="h2">Verantwortlicher</Typography>
          <p>
            Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO)
            ist die Dampsoft GmbH, Vogelsang 1, 24351 Damp, E-Mail-Adresse:{' '}
            <Link href="mailto:info@dampsoft.de">info@dampsoft.de</Link>.
          </p>
          <p>
            Unseren Datenschutzbeauftragten können Sie per E-Mail unter{' '}
            <Link href="mailto:datenschutz@dampsoft.de">
              datenschutz@dampsoft.de
            </Link>{' '}
            oder per Post unter unserer Anschrift mit dem Zusatz „der
            Datenschutzbeauftragte“ erreichen.
          </p>
          <Typography variant="h2">Browserdaten</Typography>
          <ul>
            <li>IP-Adresse Ihres Computers</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Zugriffsstatus und übertragene Datenmenge</li>
            <li>Produkt- und Versionsinformationen Ihres Browsers</li>
            <li>Betriebssystem Ihres Computers</li>
            <li>
              von welcher Website aus der Zugriff auf unsere Website erfolgte
            </li>
          </ul>
          <p>
            Diese Daten sind für uns technisch erforderlich, um Ihnen unsere
            Website anzuzeigen und um die Stabilität und Sicherheit unsere
            Website zu gewährleisten.
          </p>
          <p>
            Die IP-Adresse Ihres Computers wird dabei nur für die Zeit Ihrer
            Nutzung der Website gespeichert und im Anschluss daran unverzüglich
            gelöscht oder durch Kürzung anonymisiert.
          </p>
          <p>Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
          <Typography variant="h2">Ihre Anfrage</Typography>
          <p>
            Sie finden auf unserer Website verschiedene Kontaktmöglichkeiten,
            z.B. unsere Anschrift, unsere E-Mail-Adresse, unsere Telefonnummer
            und unser Kontaktformular.
          </p>
          <p>
            Wenn Sie mit uns Kontakt aufnehmen, nutzen wir die von Ihnen
            mitgeteilten Daten, wie z.B. Ihre E-Mail-Adresse, Ihren Namen und
            den Inhalt Ihrer Anfrage, um Ihre Anfrage zu bearbeiten.
          </p>
          <p>
            In Rahmen der Kommunikation setzen wir u.U. zusätzlich Messenger
            ein. Sie sind jederzeit berechtigt unsere weiteren
            Kommunikationsmittel zu nutzen. Nutzt der Messenger eine
            Ende-zu-Ende-Verschlüsselung, so kann der Messenger-Anbieter nicht
            auf den Nachrichteninhalt zugreifen. Jedoch hat der
            Messenger-Dienstleister u.U. Zugriff auf die Information, dass eine
            Kommunikation stattgefunden hat und welches Gerät genutzt wurde.
            Diese Daten werden aber durch den Messenger-Anbieter verarbeitet,
            lesen Sie hierzu dessen Datenschutzerklärung.
          </p>
          <p>
            Die hierbei anfallenden Daten löschen wir, nachdem die Speicherung
            nicht mehr erforderlich ist, oder schränken die Verarbeitung ein,
            falls gesetzliche Aufbewahrungspflichten bestehen.
          </p>
          <p>Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.</p>
          <Typography variant="h2">Funktionelle Cookies</Typography>
          <p>
            Wir setzen Cookies ein, um die Nutzung unserer Website komfortabel
            für Sie zu gestalten.
          </p>
          <p>
            Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer
            Website von unserem Webserver an Ihren Browser gesandt und von
            diesem auf Ihrem Rechner für einen späteren Abruf vorgehalten
            werden. Die Cookies ermöglichen die Wiedererkennung Ihres
            Internetbrowsers.
          </p>
          <p>
            Wir setzen Cookies ein, um Ihre Voreinstellungen verfügbar zu
            machen, um Ihre persönlichen Daten bei zukünftigen Interaktionen mit
            unserer Website in die Eingabemaske einzutragen zu lassen oder um
            Ihre Autorisierung sicherzustellen.
          </p>
          <p>
            Session-Cookies werden automatisiert gelöscht, wenn Sie den Browser
            schließen. Andere Cookies werden automatisiert nach einer
            vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden
            kann. Die genaue Speicherdauer können Sie den Einstellungen Ihres
            Browsers entnehmen.
          </p>
          <p>
            Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
            konfigurieren und z. B. die Annahme von Third-Party-Cookies oder
            allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie
            eventuell nicht alle Funktionen dieser Website nutzen können.
          </p>
          <p>
            Sie können die Cookies in den Sicherheitseinstellungen Ihres
            Browsers jederzeit löschen.
          </p>
          <p>Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
          <Typography variant="h2">
            Anamnese@Home / Smartphone Anamnese
          </Typography>
          <p>
            Im Auftrag von Zahnarztpraxen bieten wir (Dampsoft) die Möglichkeit
            den Anamnesebogen/Gesundheitsfragebogen bereits vor Ihren ersten
            Besuch in der Zahnarztpraxis auszufüllen und an die Praxis zu
            senden. Bei den Daten handelt es sich insbesondere um
            Gesundheitsdaten nach Art. 9 DSGVO. In diese Vorab-Übermittlung
            müssen Sie nach Art. 9 Abs. 2 lit. a DSGVO einwilligen. Diese
            Einwilligung ist freiwillig und kann jederzeit mit Wirkung für die
            Zukunft widerrufen werden. Wir selbst verarbeiten die Daten nur für
            die Dauer der Übermittlung und speichern Ihre Daten nicht dauerhaft.
          </p>
          <Typography variant="h2">Ihre Rechte</Typography>
          <p>
            Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
            betreffenden Daten:
          </p>
          <ul>
            <li>Recht auf Auskunft</li>
            <li>Recht auf Berichtigung oder Löschung</li>
            <li>Recht auf Einschränkung der Verarbeitung</li>
            <li>Recht auf Widerspruch gegen die Verarbeitung</li>
            <li>Recht auf Datenübertragbarkeit</li>
            <li>Recht auf Beschwerde bei einer Datenschutz-Aufsichtsbehörde</li>
          </ul>
          <p>
            Falls Sie uns eine Einwilligung zur Verarbeitung Ihrer Daten erteilt
            haben, können Sie diese jederzeit mit Wirkung für die Zukunft
            widerrufen.
          </p>
          <p>
            Sie können Direktwerbung jederzeit widersprechen. Falls Ihre
            besondere Situation dies erfordert, können Sie jederzeit auch
            Verarbeitungen aufgrund von Art. 6 Abs. 1 S. 1 lit. f DSGVO
            widersprechen.
          </p>
        </Grid>
      </Grid>
    </Card>
  </Wrapper>
)
