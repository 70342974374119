import styled from 'styled-components'

interface DropdownInputProps {
  name: string
  type: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus: () => void
  onBlur: () => void
}

type DropdownItemProps = {
  key: number | string | number
  onClick: () => void
} & any

export const InputWithDropdownContainer = styled.div`
  position: relative;
  margin-top: 6px;
  display: flex;
`

export const DropdownMenu = styled.ul`
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border: 1px solid #ddd;
  z-index: 1000;
`

export const DropdownItem = styled.li<DropdownItemProps>`
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: #f0f0f0; /* Optional hover effect */
  }
`

export const IconAndDropdown = styled.div`
  display: flex;
  alignitems: center;
  flex: 1;
`

export const DropdownInput = styled.input<DropdownInputProps>`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
`
