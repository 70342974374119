import React from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`

export const Loading = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  )
}
