import React, { useContext } from 'react'
import { ActionButton } from '../components/ActionButton'
import { Grid, Typography } from '@material-ui/core'
import { DocumentFlowContext } from '../contexts/DocumentFlowContext'
import { getPatient } from '../contexts/PatientContext'
import { useFormikContext } from 'formik'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { Card } from '../components/Card'
import { FormSpacer } from '../components/CardHeader'
import styled from 'styled-components'
import { theme, colors } from '../utils/theme'

const PatientDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${theme.breakpoints.down('sm')} {
    align-items: flex-start;
    flex-direction: column;
  }
`

const PatientDetail = styled.span`
  margin-right: 5px;
  margin-bottom: 10px;
`

const DocumentTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

const ReviewButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${theme.breakpoints.down('sm')} {
    justify-content: flex-start;
    margin: 10px 0 40px;
  }
`

const BoldText = styled.p`
  font-weight: 600;
  color: ${colors.grey};
`

export const Summary = () => {
  const patient = getPatient(useFormikContext().values)
  const { setStepIndex, templates } = useContext(DocumentFlowContext)

  return (
    <Card>
      <Grid item>
        <Grid item xs={12}>
          <Typography variant="h1">
            <FormattedMessage id="summary.title" />
          </Typography>
          <FormSpacer />
          <p>
            <FormattedMessage id="summary.subTitle" />
          </p>
          <FormSpacer />
        </Grid>
        <Grid container>
          <Grid item xs={12} md={10}>
            <PatientDetails>
              <PatientDetail>
                <BoldText>
                  <FormattedMessage id="summary.patient" />:
                </BoldText>
              </PatientDetail>
              <PatientDetail>
                {patient.first_name} {patient.last_name} (
                <FormattedDate value={patient.date_of_birth} />)
              </PatientDetail>
            </PatientDetails>
          </Grid>
          <Grid item xs={12} md={2}>
            <ReviewButtonContainer>
              <ActionButton
                title={<FormattedMessage id="summary.reviewButton" />}
                action={() => setStepIndex(0)}
              />
            </ReviewButtonContainer>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormSpacer />
          <BoldText>
            <FormattedMessage id="summary.documentListTitle" />:
          </BoldText>
          <FormSpacer />
        </Grid>
        {templates.map((t, index) => (
          <Grid container key={t.title}>
            <Grid item xs={12} md={10}>
              <DocumentTitle>
                {index + 1}. {t.title}
              </DocumentTitle>
            </Grid>
            <Grid item xs={12} md={2}>
              <ReviewButtonContainer>
                <ActionButton
                  title={<FormattedMessage id="summary.reviewButton" />}
                  action={() => setStepIndex(index + 1)}
                />
              </ReviewButtonContainer>
            </Grid>
            <FormSpacer />
          </Grid>
        ))}
        <p>
          <FormattedMessage id="summary.submitInstructions" />
        </p>
      </Grid>
    </Card>
  )
}
