import { createTheme } from '@material-ui/core/styles'
import { createGlobalStyle } from 'styled-components'

export const colors = {
  white: '#fff',
  lightGrey: '#F1F2F4',
  grey: '#444',
  black: '#333',
  blue: '#015270',
  red: '#f44336',
}

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
    },
    error: {
      main: colors.red,
    },
  },
  typography: {
    h1: {
      fontSize: '30px',
      fontWeight: 400,
      color: colors.blue,
      padding: '10px 0',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 400,
      color: colors.blue,
    },
  },
})

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${colors.lightGrey};
    font-family: Arial, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

   .MuiContainer-root {
    ${theme.breakpoints.down('sm')} {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
`
