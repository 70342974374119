import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { FastField, FieldProps } from 'formik'
import { InputContainer, InputField } from '../components/FormElement'
import { FormattedMessage } from 'react-intl'
import { Card } from '../components/Card'
import { CardHeader } from '../components/CardHeader'
import DatePicker from '../components/DatePicker'
import Label from '../styled/Label'
import RequiredStar from '../styled/RequiredStar'

const Wrapper = styled.div`
  .MuiGrid-item {
    margin: auto;
  }
`

export const PatientInfo = () => {
  return (
    <Card>
      <Wrapper>
        <Grid item>
          <CardHeader title={<FormattedMessage id="personalData.title" />} />
          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="personalData.firstName" />
                <RequiredStar />
              </Label>
              <FastField name="patient.first_name">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="first_name"
                    inputProps={{ 'aria-label': 'Vorname' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="personalData.lastName" />
                <RequiredStar />
              </Label>
              <FastField name="patient.last_name">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="last_name"
                    inputProps={{ 'aria-label': 'Nachname' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={6}>
            <DatePicker
              name="patient.date_of_birth"
              label="personalData.dateOfBirth"
              required
            />
          </Grid>
        </Grid>
      </Wrapper>
    </Card>
  )
}
